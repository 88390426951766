import Vue from 'vue';
import Vuex from 'vuex';
import { CHECKOUT_OLD_LOGGED_STEPS, CHECKOUT_OLD_GUEST_STEPS } from '@/settings/Wizard';

const steps = { ...CHECKOUT_OLD_LOGGED_STEPS, ...CHECKOUT_OLD_GUEST_STEPS };
const options = {};
Object.keys(steps).forEach((key) => {
	options[key] = {};
});

Vue.use(Vuex);

const Store = new Vuex.Store({
	strict: process.env.VUE_APP_ENV !== 'production',
	state: {
		cartId: '',
		...options,
	},
	getters: {
		getStepInformation(state) {
			return (payload) => {
				if (Object.keys(state).includes(payload)) {
					return state[payload];
				}
				return null;
			};
		},
	},
	mutations: {
		setState(state, payload) {
			const [name] = Object.keys(payload);
			state[name] = payload[name];
		},
	},
	actions: {
		saveCart({ commit }, payload) {
			try {
				commit('setState', { cartId: payload });
				return Promise.resolve(true);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		saveInfo({ commit }, payload) {
			const [name] = Object.keys(payload);
			if (Object.keys(options).includes(name)) {
				commit('setState', payload);
				return Promise.resolve(true);
			}
			return Promise.reject(new Error('invalid_step'));
		},
		removeRegister({ commit }) {
			Object.keys(options).forEach((item) => {
				const payload = {};
				payload[item] = {};
				commit('setState', payload);
			});
		},
		removeCart({ commit }) {
			commit('setState', { cartId: '' });
		},
	},
});

export default Store;
