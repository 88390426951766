<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-7 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('payment_method') }}
				</div>
			</div>
			<div
				v-if="!$user.auth()"
				class="col-xs-12 col-lg-5 d-flex">
				<div
					class="h4"
					:class="['xs','sm', 'md'].includes(windowWidth) ? 'mb-2 text-left' : 'my-auto ml-auto text-right'">
					<div class="text-primary">
						<span class="font-weight-bold">{{ translate('sponsor') }}: </span>
						<span>{{ sponsor.name }} </span>
						<span>({{ translate('id_word') }}: {{ sponsor.id }})</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<li
						v-if="paymentMethods.length > 1"
						class="list-group-item">
						<div class="row">
							<div class="col-12">
								<div class="h4 my-2">
									{{ translate('select_payment_method') }}
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-8">
								<div
									class="btn-group btn-group-lg payment-method"
									role="group">
									<b-button
										v-for="item in paymentMethods"
										:key="item.id"
										:variant="paymentMethodSelected === item.attributes.method_code_name ? 'primary' : 'light'"
										@click="paymentMethodSelected = item.attributes.method_code_name">
										{{ translate(item.attributes.method_code_name) }}
									</b-button>
								</div>
							</div>
						</div>
					</li>
					<template
						v-if="this.$user.auth()">
						<li class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('billing_information') }}
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12">
									<b-alert show>
										{{ translate('billing_disclaimer') }}
									</b-alert>
								</div>
								<div class="col-12 mb-3">
									<div class="custom-control custom-checkbox">
										<input
											id="customCheck1"
											v-model="sameAs"
											type="checkbox"
											class="custom-control-input">
										<label
											class="custom-control-label"
											for="customCheck1">{{ translate('same_as_shipping') }}</label>
									</div>
								</div>
							</div>
							<template v-if="!sameAs">
								<div class="row">
									<div class="col-12">
										<div class="h4 my-2">
											{{ translate( addAddress ? 'add_new_address' : 'select_your_address') }}
										</div>
									</div>
								</div>
								<div class="row m-3">
									<div class="col-12 address-list">
										<address-radio-button
											:address-data="addressData"
											:address-loading="addressLoading"
											:form-import="form"
											:hide-new-address="addAddress"
											:display-add-new-address="false"
											:borderless="true"
											@add-address="addressFormState" />
										<div
											v-if="!addAddress && !addressLoading"
											class="row">
											<div class="col-12">
												<hr class="mt-0">
											</div>
										</div>
										<div
											v-if="addAddress && !addressLoading"
											class="pt-4">
											<address-form
												:user-country="true"
												:countries="countries"
												:states="states"
												:form-import="form"
												:errors-import="addressErrors.errors"
												:prefix-errors="''"
												:display-cancel-button="false"
												:display-submit-button="false"
												:display-goback-button="false"
												:display-override-toggle="showOverrideValidation"
												:label-cancel-button="translate('see_address_list')" />
										</div>
									</div>
								</div>
								<div
									v-if="!addressLoading"
									class="row m-3">
									<div
										:class="{'mb-3': ['xs', 'sm'].includes(windowWidth) }"
										class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
										<button
											v-if="addAddress && hasAddressData"
											:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'pl-0'"
											class="btn btn-link b-4 float-left"
											@click="addressFormState">
											{{ translate('see_address_list') }}
										</button>
										<button
											v-else-if="!addAddress"
											:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'pl-0'"
											class="btn btn-link b-4 float-left"
											@click="addressFormState">
											{{ translate('use_another_address') }}
										</button>
									</div>
									<div
										v-if="addAddress"
										class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
										<button
											:disabled="(!hasAddressData && !addAddress)"
											:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
											class="btn btn-outline-primary b-4 float-right"
											@click="newAddress(form)">
											{{ translate('save_address_button') }}
										</button>
									</div>
								</div>
							</template>
							<div class="row m-3">
								<div
									v-if="typeof validationErrors[`${steps[$route.name].validation}.billing.address_id`] !== 'undefined'"
									id="address"
									class="col-12">
									<b-alert
										variant="danger"
										show>
										<div
											v-for="error in validationErrors[`${steps[$route.name].validation}.billing.address_id`]"
											:key="error">
											<span
												v-text="error" />
											<br>
										</div>
									</b-alert>
								</div>
							</div>
						</li>
					</template>
					<form
						@submit.prevent="prepareForStorage()"
						@keydown="clearValidationError($event.target.name);"
						@change="handleAddressFormChange($event.target.name);">
						<template
							v-if="!this.$user.auth()">
							<li class="list-group-item">
								<div class="row">
									<div class="col-12">
										<div class="h4 my-2">
											{{ translate('billing_information') }}
										</div>
									</div>
								</div>
								<div class="row m-3">
									<div class="col-12">
										<b-alert show>
											{{ translate('billing_disclaimer') }}
										</b-alert>
									</div>
									<div class="col-12 mb-3">
										<div class="custom-control custom-checkbox">
											<input
												id="customCheck1"
												v-model="sameAs"
												type="checkbox"
												class="custom-control-input">
											<label
												class="custom-control-label"
												for="customCheck1">{{ translate('same_as_shipping') }}</label>
										</div>
									</div>
								</div>
								<div v-if="!sameAs">
									<div class="row m-3">
										<div class="col-12 col-md-6">
											<div class="form-group">
												<input-text
													:id="`${steps[$route.name].validation}.billing.first_name`"
													:label="translate('first_name')"
													:setter-value="form.first_name"
													:errors="validationErrors[`${steps[$route.name].validation}.billing.first_name`]"
													:required="true"
													:disabled="sameAs"
													type="text"
													@dataChanged="form.first_name = $event" />
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="form-group">
												<input-text
													:id="`${steps[$route.name].validation}.billing.last_name`"
													:label="translate('last_name')"
													:setter-value="form.last_name"
													:errors="validationErrors[`${steps[$route.name].validation}.billing.last_name`]"
													:required="true"
													:disabled="sameAs"
													type="text"
													@dataChanged="form.last_name = $event" />
											</div>
										</div>
									</div>
									<div class="row m-3">
										<div class="col-12 col-md-4">
											<div class="form-group">
												<input-text
													:id="`${steps[$route.name].validation}.billing.address`"
													:label="translate('address')"
													:setter-value="form.address"
													:errors="validationErrors[`${steps[$route.name].validation}.billing.address`]"
													:required="true"
													:disabled="sameAs"
													type="text"
													@dataChanged="form.address = $event" />
											</div>
										</div>
										<div class="col-12 col-md-4">
											<div class="form-group">
												<input-text
													:id="`${steps[$route.name].validation}.billing.address2`"
													:label="translate('address2')"
													:setter-value="form.address2"
													:errors="validationErrors[`${steps[$route.name].validation}.billing.address2`]"
													:disabled="sameAs"
													type="text"
													@dataChanged="form.address2 = $event" />
											</div>
										</div>
										<div class="col-12 col-md-4">
											<div class="form-group">
												<input-text
													:id="`${steps[$route.name].validation}.billing.postcode`"
													:label="translate('postcode')"
													:setter-value="form.postcode"
													:errors="validationErrors[`${steps[$route.name].validation}.billing.postcode`]"
													:required="true"
													:disabled="sameAs"
													type="text"
													@dataChanged="form.postcode = $event" />
											</div>
										</div>
									</div>
									<div class="row m-3">
										<div class="col-12 col-md-4">
											<div class="form-group">
												<input-text
													:id="`${steps[$route.name].validation}.billing.city`"
													:label="translate('city')"
													:setter-value="form.city"
													:errors="validationErrors[`${steps[$route.name].validation}.billing.city`]"
													:disabled="sameAs"
													:required="true"
													type="text"
													@dataChanged="form.city = $event" />
											</div>
										</div>
										<div class="col-12 col-md-4">
											<div class="form-group">
												<label :for="`${steps[$route.name].validation}.billing.country_code`">{{ translate('country') }} <span class="text-danger">*</span></label>
												<select
													:id="`${steps[$route.name].validation}.billing.country_code`"
													v-model="form.country_code"
													:name="`${steps[$route.name].validation}.billing.country_code`"
													:disabled="sameAs || !allowAnotherCountry"
													:class="typeof validationErrors[`${steps[$route.name].validation}.billing.country_code`] !== 'undefined' ? 'is-invalid' : ''"
													class="form-control">
													<option value="">
														{{ translate('select_country') }}
													</option>
													<template v-if="hasCountries">
														<option
															v-for="item in countries"
															:key="item.id"
															:value="item.attributes.code">
															{{ translate(item.attributes.code.toLowerCase()) }}
														</option>
													</template>
												</select>
												<template v-if="typeof validationErrors[`${steps[$route.name].validation}.billing.country_code`] !== 'undefined'">
													<span
														v-for="error in validationErrors[`${steps[$route.name].validation}.billing.country_code`]"
														:key="error"
														class="custom-invalid-feedback animated fadeIn text-danger"
														v-text="error" />
												</template>
											</div>
										</div>
										<div class="col-12 col-md-4">
											<div class="form-group">
												<label :for="`${steps[$route.name].validation}.billing.region_id`">{{ translate('state') }} <span class="text-danger">*</span></label>
												<select
													:id="`${steps[$route.name].validation}.billing.region_id`"
													v-model="form.region_id"
													:name="`${steps[$route.name].validation}.billing.region_id`"
													:disabled="sameAs"
													:class="typeof validationErrors[`${steps[$route.name].validation}.billing.region_id`] !== 'undefined' ? 'is-invalid' : ''"
													class="form-control">
													<option value="">
														{{ translate('select_state') }}
													</option>
													<template v-if="hasState">
														<option
															v-for="item in states"
															:key="item.id"
															:value="item.id">
															{{ item.attributes.name }}
														</option>
													</template>
												</select>
												<template v-if="typeof validationErrors[`${steps[$route.name].validation}.billing.region_id`] !== 'undefined'">
													<span
														v-for="error in validationErrors[`${steps[$route.name].validation}.billing.region_id`]"
														:key="error"
														class="custom-invalid-feedback animated fadeIn text-danger"
														v-text="error" />
												</template>
											</div>
										</div>
									</div>
								</div>
							</li>
						</template>
						<li
							v-show="paymentMethodSelected === 'purchaseorder'"
							class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('cc_information') }}
									</div>
								</div>
							</div>
							<div class="row mx-2">
								<div
									:class="['xs'].includes(windowWidth) ? 'p-0' : ''"
									class="col-12">
									<template v-for="(item, index) in displayedAllowedCC">
										<img
											:key="index"
											:src="require(`@/assets/images/common/cc/logos/${item}.png`)"
											align="right"
											class="text-right mr-2 resize-img">
									</template>
								</div>
							</div>
							<div class="row m-3">
								<div class="col">
									<div class="form-group">
										<form id="cc-form">
											<div class="form-group">
												<label for="cc-name">{{ translate('name_on_card') }} <span class="text-danger">*</span></label>
												<span
													id="cc-name"
													:class="creditCardHasErrors('name') ? 'is-invalid' : ''"
													class="form-field form-control" />
												<template v-if="creditCardHasErrors('name')">
													<p
														v-for="error in creditCardErrors.name.errorMessages"
														:key="error"
														class="custom-invalid-feedback animated fadeIn text-danger mb-0"
														v-text="error" />
												</template>
											</div>
											<div class="form-group">
												<label for="cc-number">{{ translate('card_number') }} <span class="text-danger">*</span></label>
												<span
													id="cc-number"
													:class="creditCardHasErrors('number') ? 'is-invalid' : ''"
													class="form-field form-control" />
												<template v-if="creditCardHasErrors('number')">
													<p
														v-for="error in creditCardErrors.number.errorMessages"
														:key="error"
														class="custom-invalid-feedback animated fadeIn text-danger mb-0"
														v-text="error" />
												</template>
											</div>
											<div class="row">
												<div class="form-group col-12 col-md-4">
													<label for="cc-expiration-month">{{ translate('expiry_month') }} <span class="text-danger">*</span></label>
													<span
														id="cc-expiration-month"
														:class="creditCardHasErrors('expiration_month') ? 'is-invalid' : ''"
														class="form-field form-control" />
													<template v-if="creditCardHasErrors('expiration_month')">
														<p
															v-for="error in creditCardErrors.expiration_month.errorMessages"
															:key="error"
															class="custom-invalid-feedback animated fadeIn text-danger mb-0"
															v-text="error" />
													</template>
												</div>
												<div class="form-group col-12 col-md-4">
													<label for="cc-expiration-year">{{ translate('expiry_year') }} <span class="text-danger">*</span></label>
													<span
														id="cc-expiration-year"
														:class="creditCardHasErrors('expiration_year') ? 'is-invalid' : ''"
														class="form-field form-control" />
													<template v-if="creditCardHasErrors('expiration_year')">
														<p
															v-for="error in creditCardErrors.expiration_year.errorMessages"
															:key="error"
															class="custom-invalid-feedback animated fadeIn text-danger mb-0"
															v-text="error" />
													</template>
												</div>
												<div class="form-group col-12 col-md-4">
													<label for="cc-cvc">{{ translate('cvv') }} <span class="text-danger">*</span></label>
													<span
														id="cc-cvc"
														:class="creditCardHasErrors('security_code') ? 'is-invalid' : ''"
														class="form-field form-control" />
													<template v-if="creditCardHasErrors('security_code')">
														<p
															v-for="error in creditCardErrors.security_code.errorMessages"
															:key="error"
															class="custom-invalid-feedback animated fadeIn text-danger mb-0"
															v-text="error" />
													</template>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</li>
						<li
							v-show="paymentMethodSelected === 'checkmo'"
							class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('cash_payment') }}
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12">
									<b-alert
										show
										variant="warning"
										class="h5">
										<b>{{ translate('important_note').toUpperCase() }}</b>: {{ translate('international_wires_only') }}
									</b-alert>
								</div>
							</div>
						</li>
						<li
							v-if="paymentMethodSelected === 'cashondelivery'"
							class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('wallet_information') }}
									</div>
								</div>
							</div>
							<div class="row mx-3">
								<div class="col">
									<div
										v-if="!loadingWalletBalance && balance.hasOwnProperty(wallet.type)"
										class="h4 text-right mb-0">
										{{ translate('current_balance') }}: {{ balance[wallet.type] }}
									</div>
								</div>
							</div>
							<div
								:class="{'px-4': windowWidth === 'md', 'px-5': !['xs','md'].includes(windowWidth) }"
								class="row">
								<div class="col-12">
									<input-text
										:id="`${steps[$route.name].validation}.wallet_password`"
										:name="`${steps[$route.name].validation}.wallet_password`"
										:required="true"
										:label="translate('wallet_password')"
										:setter-value="wallet.password"
										:errors="validationErrors[`${steps[$route.name].validation}.payment_method.wallet_password`]"
										type="password"
										@dataChanged="wallet.password = $event" />
								</div>
							</div>
							<div
								v-if="typeof validationErrors[`${steps[$route.name].validation}.payment_method.wallet_type`] !== 'undefined'"
								:class="{'px-4': windowWidth === 'md', 'px-5': !['xs','md'].includes(windowWidth) }"
								class="row">
								<div
									:id="`${steps[$route.name].validation}.payment_method.wallet_type`"
									:class="{'px-0': !['xs'].includes(windowWidth) }"
									class="col-12">
									<b-alert
										variant="danger"
										show>
										<div
											v-for="error in validationErrors[`${steps[$route.name].validation}.payment_method.wallet_type`]"
											:key="error">
											<span
												v-text="error" />
											<br>
										</div>
									</b-alert>
								</div>
							</div>
						</li>
					</form>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 ml-auto my-4 text-right">
				<div
					class="btn-group w-100"
					role="group">
					<b-button
						:disabled="!!preparing"
						variant="light"
						size="lg"
						@click="nextStep(true)">
						{{ translate('previous') }}
					</b-button>
					<b-button
						:disabled="validating || !!preparing || addAddress"
						variant="primary"
						size="lg"
						@click="prepareForStorage()">
						<i
							v-if="validating"
							class="fas fa-spinner fa-spin mr-2" />{{ translate('continue_to_confirm') }}
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ALLOW_CHANGE_COUNTRY_TO_USER as allowAnotherCountry } from '@/settings/AddressBook';
import { ALLOWEDCC_BY_COUNTRY } from '@/settings/CreditCard';
import { WALLET_TYPES } from '@/settings/Wallets';
import {
	// UNPROCESSABLE,
	SYSTEM_ALERT,
} from '@/settings/Errors';
// import { ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries } from '@/settings/Shipping';
import WindowSizes from '@/mixins/WindowSizes';
import AddressRadioButton from '@/components/AddressRadioButton';
import AddressForm from '@/components/AddressForm';
import AddressBook from '@/util/AddressBook';
import InputText from '@/components/InputText';
import Country from '@/util/Country';
import Cart from '@/util/Cart';
import Wallets from '@/util/Wallets';
import CommonMix from './mixins/Common';

export default {
	name: 'CheckoutOldPaymentMethod',
	components: {
		InputText,
		AddressRadioButton,
		AddressForm,
	},
	mixins: [CommonMix, WindowSizes],
	data() {
		return {
			countryData: new Country(),
			stateData: new Country(),
			state: new Country(),
			countryIso: new Country(),
			paymentMethodData: new Cart(),
			paymentMethodSelected: 0,
			path: '',
			sameAs: false,
			wallets: new Wallets(),
			wallet: {
				type: '',
				password: '',
			},
			walletTypes: WALLET_TYPES,
			sponsor: {
				id: '',
				name: '',
			},
			identityId: null,
			type: '',
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				address_id: '',
			},
			paymentForm: {},
			allowAnotherCountry,
			alert: new this.$Alert(),
			WindowSizes,
			// addressVerificationCountries,
			creditCardErrors: {},
			lacoreFailed: false,
			paymentToken: null,
			canLeave: false,
			showOverrideValidation: false,
			addressBook: new AddressBook(),
			createAddress: new AddressBook(),
			loadAddress: new AddressBook(),
			addAddress: false,
			hasAddressId: false,
		};
	},
	computed: {
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				if (allowAnotherCountry === false) {
					return response.filter((item) => item.attributes.code === this.country);
				}
				return response;
			} catch (error) {
				return [];
			}
		},
		hasCountries() {
			return !!this.countries.length;
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasState() {
			return !!this.states.length;
		},
		paymentMethods() {
			try {
				let { data } = this.paymentMethodData.data.response.data;
				if (!this.$user.auth()) {
					data = data.filter((paymentMethod) => paymentMethod.attributes.method_code_name !== 'cashondelivery');
				}
				return data;
			} catch (error) {
				return [];
			}
		},
		balance() {
			try {
				return this.wallets.data.response.data.response.balance;
			} catch (error) {
				return [];
			}
		},
		loadingWalletBalance() {
			return !!this.wallets.data.loading;
		},
		addressData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		addressLoading() {
			return !!this.addressBook.data.loading;
		},
		hasAddressData() {
			const response = this.addressData.length;
			return !!response;
		},
		addressErrors() {
			return this.createAddress.data.errors;
		},
		displayedAllowedCC() {
			return ALLOWEDCC_BY_COUNTRY[this.country] ?? ALLOWEDCC_BY_COUNTRY.default;
		},
	},
	watch: {
		sameAs: function sameAs(value) {
			if (value) {
				this.clearValidationError(true);
				const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
				const { shipping } = this.getStepInformation(step);
				Object.keys(shipping.shipping_address).forEach((property) => {
					if (property !== 'signature_required') this.form[property] = shipping.shipping_address[property];
				});
			} else {
				Object.keys(this.form).forEach((key) => {
					this.form[key] = '';
				});
				this.initializeAddress();
				this.form.country_code = this.country;
			}
		},
		hasAddressData: {
			deep: true,
			handler(newVal) {
				this.addAddress = !newVal;
			},
		},
		'form.address_id': function addressId(value) {
			this.clearValidationError('payment.billing.address_id');
			this.hasAddressId = false;
			if (value.length || !!value) {
				this.hasAddressId = true;
				this.setAddressInfo(value);
			}
		},
	},
	mounted() {
		this.paymentMethodData.getPaymentMethods({ country_code: this.getStoredCountry() }).then(() => {
			if (this.path === 'guest' || this.path === 'customer') {
				const cashIndex = this.paymentMethods.findIndex((x) => x.attributes.method_code_name === 'checkmo');
				if (cashIndex >= 0) {
					this.paymentMethods.splice(cashIndex, 1);
				}
			}
			if (this.paymentMethodSelected === 0) {
				this.paymentMethodSelected = this.paymentMethods[0].attributes.method_code_name;
			}
			if (this.$user.auth() && this.paymentMethods.some((method) => method.attributes.method_code_name === 'cashondelivery')) {
				this.wallet.type = this.walletTypes.commission;
				this.wallets.getBalance();
			}
		}).finally(() => {
			// Initialize Lacore payment form
			this.lacoreFormSetup();
			let timeout = 1000;
			if (this.paymentMethodSelected === 'purchaseorder') {
				timeout = 3000;
			}

			// Waiting for LP Form
			this.preparing = true;
			setTimeout(() => {
				this.preparing = false;
			}, timeout);
		});
	},
	created() {
		this.countryData.getCountries();
		this.getGeneralInformation();
		this.form.country_code = this.country;
		this.stateData.getStates(this.form.country_code);
		this.setStoredSameAs();
		this.initializeAddress();
		this.getStoredInformation();
		return null;
	},
	methods: {
		setStoredSameAs() {
			if (this.getStepInformation().payment !== undefined) {
				const { same_as_shipping: sameAs } = { ...this.getStepInformation().payment.billing };
				if (sameAs === true) {
					const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
					const { shipping } = this.getStepInformation(step);
					Object.keys(shipping.shipping_address).forEach((key) => {
						this.form[key] = shipping.shipping_address[key];
					});
				}
				this.sameAs = sameAs;
			}
		},
		initializeAddress() {
			if (this.$user.auth()) {
				this.addressBook.getAddresses(this.$user.details().id).then((response) => {
					if (this.form.address_id.length === 0 && response.length) this.form.address_id = response[0].id;
				});
			}
		},
		setAddressInfo(addressId) {
			if (this.$user.auth()) {
				this.loadAddress.getAddress(this.$user.details().id, addressId).then((response) => {
					this.form.first_name = response.attributes.first_name;
					this.form.last_name = response.attributes.last_name;
					this.form.address = response.attributes.address;
					this.form.address2 = response.attributes.address2;
					this.form.city = response.attributes.city;
					this.form.postcode = response.attributes.postcode;
					this.form.country_code = response.attributes.country.code;
					this.form.region_id = response.attributes.region.id;
				}).catch(() => {
					this.form.address_id = '';
					this.initializeAddress();
				});
			}
		},
		getGeneralInformation() {
			const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
			const previous = this.getStepInformation(step);
			const {
				sponsor, identity_id: identityId, path,
			} = previous;
			try {
				const { shipping_address: shippingAddress } = previous.shipping;
				if (typeof shippingAddress === 'undefined' || typeof sponsor === 'undefined') {
					this.nextStep(true);
					return null;
				}
			} catch (error) {
				this.nextStep(true);
				return null;
			}

			this.sameAs = true;
			if (!this.$user.auth()) {
				this.path = path;
				this.identityId = identityId;
				this.getSponsorInfo(sponsor).then((response) => {
					Object.keys(this.sponsor).forEach((value) => {
						this.sponsor[value] = response[value];
					});
				});
			}

			return null;
		},
		getStoredInformation() {
			const { payment } = { ...this.getStepInformation() };
			if (typeof payment !== 'undefined' && typeof payment.payment_method !== 'undefined') {
				this.paymentMethodSelected = payment.payment_method.name;
				if (this.path === 'guest' && this.paymentMethodSelected === 'checkmo') {
					this.paymentMethodData.getPaymentMethods({ country_code: this.getStoredCountry() }).then(() => {
						this.paymentMethods.splice(this.paymentMethods.findIndex((x) => x.attributes.method_code_name === 'checkmo'), 1);
						this.paymentMethodSelected = this.paymentMethods[0].attributes.method_code_name;
					});
				}
				if (this.paymentMethodSelected === 'cashondelivery') {
					this.wallet.type = payment.payment_method.wallet_type;
				}
			}
			try {
				const { billing: data } = payment;
				const { country_code: countryCode } = data;
				Object.keys(this.form).forEach((key) => {
					if (typeof data[key] !== 'undefined') {
						this.form[key] = data[key];
					}
				});
				if (countryCode !== this.country) {
					this.form.region_id = '';
				}
			} catch (error) {
				return null;
			}
			return null;
		},
		async prepareForStorage() {
			this.preparing = true;
			let billing = { ...this.form, same_as_shipping: this.sameAs };
			if (this.hasAddressId) {
				billing = { address_id: this.form.address_id, same_as_shipping: this.sameAs };
			}

			let validation = {
				step: this.steps[this.$route.name].validation, sponsor: this.sponsor.id, identity_id: this.identityId, path: this.path,
			};

			const paymentMethod = { name: this.paymentMethodSelected };

			this.lacoreFailed = false;
			if (this.paymentMethodSelected === 'purchaseorder') {
				// await this.handleLacoreResponse();
				paymentMethod.token = this.paymentToken;
			}

			if (this.paymentMethodSelected === 'cashondelivery') {
				const wallet = { ...this.wallet };
				paymentMethod.wallet_type = wallet.type;
				paymentMethod.wallet_password = wallet.password;
			}

			const payment = { billing, payment_method: paymentMethod };
			validation = { ...validation, payment };
			this.validateStep(validation).then((response) => {
				validation = { ...validation, card_data: { ...response.response } };
				if (!this.lacoreFailed) {
					this.saveStep(validation);
				}
			}).catch((error) => {
				if (error.status === 422) {
					if (typeof this.validationErrors.cart_id !== 'undefined') {
						let response = '';
						this.validationErrors.cart_id.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.canLeave = true;
							this.$router.replace({ name: 'Store' });
						}, 6000);
					}
					if (typeof this.validationErrors.identity_id !== 'undefined') {
						this.alert.toast('error', this.translate('identity_error'), { timer: 6000 });
					}
					if (typeof this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token`] !== 'undefined' && !this.lacoreFailed) {
						this.creditCardErrors.number.errorMessages = this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token`];
					}
				}
			}).finally(() => { this.preparing = false; });
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'billing.billing.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
		},
		creditCardHasErrors(field) {
			if (typeof this.creditCardErrors[field] !== 'undefined' && this.creditCardErrors[field].errorMessages.length > 0) {
				return true;
			}
			return false;
		},
		addressFormState() {
			if (!this.addAddress) {
				this.clearAddressForm();
			} else {
				this.setAddressInfo(this.form.address_id);
			}
			if (Object.keys(this.addressErrors.errors).length > 0) {
				const errorFields = Object.keys(this.addressErrors.errors);
				errorFields.forEach((field) => {
					delete this.addressErrors.errors[field];
					this.addressErrors.errors = { ...this.addressErrors.errors };
				});
			}
			this.addAddress = !this.addAddress;
		},
		clearAddressForm() {
			this.form.first_name = '';
			this.form.last_name = '';
			this.form.address = '';
			this.form.address2 = '';
			this.form.city = '';
			this.form.postcode = '';
			// this.form.country_code = '';
			this.form.region_id = '';
			this.showOverrideValidation = false;
		},
		newAddress(form) {
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);

			const payload = { ...form };
			payload.is_billing = true;
			// if (!this.showOverrideValidation) {
			// 	payload.override_validation = undefined;
			// }

			this.createAddress.saveAddress(this.$user.details().id, payload).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });

				this.addressBook.getAddresses(this.$user.details().id).then((response) => {
					if (response.length) {
						const lastAddressPos = response.length - 1;
						this.form.address_id = response[lastAddressPos].id;
					}
				});

				this.addAddress = !this.addAddress;
			}).catch(() => {
				// if (UNPROCESSABLE.includes(this.addressErrors.status)) {
				// 	Object.keys(this.addressErrors.errors).forEach((errorKey) => {
				// 		if (errorKey === 'suggestion') {
				// 			this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
				// 				let address = '';
				// 				Object.keys(suggestionResponse.response.address).forEach((item, index) => {
				// 					if (suggestionResponse.response.address[item] !== '' && item !== 'region_id') {
				// 						address += `${suggestionResponse.response.address[item]}`;
				// 						if (index < (Object.keys(suggestionResponse.response.address).length) - 2) {
				// 							address += ', ';
				// 						}
				// 					}
				// 				});
				// 				const trans = {
				// 					title: this.translate('invalid_address'),
				// 					text: this.translate('suggestion_address', { address }),
				// 				};
				// 				const options = {
				// 					config: {
				// 						icon: 'error',
				// 						showCancelButton: true,
				// 						confirmButtonText: this.translate('accept_sugestion'),
				// 						cancelButtonText: this.translate('manual_change'),
				// 						reverseButtons: true,
				// 						allowOutsideClick: false,
				// 						allowEscapeKey: false,
				// 					},
				// 				};
				// 				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
				// 					Object.keys(suggestionResponse.response.address).forEach((suggestionItem) => {
				// 						Object.keys(this.form).forEach((formItem) => {
				// 							if (formItem === suggestionItem) {
				// 								this.form[formItem] = suggestionResponse.response.address[suggestionItem];
				// 							}
				// 						});
				// 					});
				// 					this.newAddress(this.form);
				// 				}).catch(() => {});
				// 			}).catch(() => {});
				// 		} else if (errorKey === 'address' && !!this.form.address.trim().length && this.addressVerificationCountries.includes(this.form.country_code)) {
				// 			this.showOverrideValidation = true;
				// 		}
				// 	});
				// }
				if (SYSTEM_ALERT.includes(this.addressErrors.status) || this.addressErrors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('CheckoutOld')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (to.name.includes('Store')) {
			next();
			if (this.canLeave) {
				const alert = new this.$Alert();
				alert.close();
				this.removeRegister();
			}
		}
	},
};
</script>

<style>
	.address-list .card-body {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
</style>
